.col-3-grid{
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-template-rows: auto auto min-content auto 50px auto ;
}
.col-2-seg{
    display: grid;
    grid-template-columns: 70% 30%;
}
.Col-2-sub{
    display: grid;
    grid-template-columns: 20% 80%;
    i{
        font-size: 60px;
        color: $dark-grey;
    }
}
.two-col-grid{
    grid-column-start: 2;
    grid-column-end: 4;
    height: auto;
}
.three-col-grid{
    grid-column-start: 1;
    grid-column-end: 4;
    height: 5px;
    padding: 0;
    margin: 0;

}
.center-grid{
    grid-column-start:2;
    width: 100%;
}
.col-1-grid{
    display: -ms-grid;
    grid-template-columns: 1fr;
}
.color-red{
    color: rgb(251, 50, 50);
}
.cursor-p{
    cursor:pointer;
}
.border-none{
    border-style: none;
    border-width: 0ch;
}
.Col-4-Ingreso{
    display: grid;
    grid-template-columns: 20% 30% 25% 25%;
    margin: 0px 0px 5px 0px;
    padding: 5px 5px;
    border: 0.1px;
    border-style: solid;
    border-color: $dark-blue;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-size: 14px;
}

.Col-5-ot{
    display: grid;
    grid-template-columns: 30% 20% 15% 10% 15% ;
    padding: 0;
    margin: 0;
}
.col-5HT{
    display: grid;
    grid-template-columns: 10% 30% 20% 30% 10% ;
    padding: 0;
    margin: 0;
}

.col-6HT{
    display: grid;
    grid-template-columns: 10% 30% 20% 30% 5% 5% ;
    padding: 0;
    margin: 0;
}

.col-6HEQ{
    display: grid;
    grid-template-columns: 16.66% 16.66%  16.66% 16.66% 16.66% 16.66% ;
    padding: 0;
    margin: 0;
}
.col-7HT{
    display: grid;
    grid-template-columns: 10% 20% 20% 20% 15% 5%  5% 5%;
    padding: 0;
    margin: 0;
}
.col-8HT{
    display: grid;
    grid-template-columns: 10% 20% 20% 15% 20% 5%  5%;
    padding: 0;
    margin: 0;
}

.col-9HT{
    display: grid;
    grid-template-columns: 10% 20% 20% 15% 15% 5%  5% 5%;
    padding: 0;
    margin: 0;
}
.col-10eq{
    display: grid;
    grid-template-columns: 17% 10% 10% 10% 7% 8% 10% 10% 10% 4% 4%;
    margin: 0%;
    padding: 0%;
    min-height: 30px;
}
.col-9eq{
    display: grid;
    grid-template-columns: 14% 9% 9% 10% 8% 10% 10% 10% 20%;
    margin: 0%;
    padding: 0%;
    min-height: 30px;
}
.accesorios{
    display: block;
}
.datauseri_21{
    display: grid;
    grid-template-columns: 45% 40% 15%;
    align-content: space-between;
    align-items: center;
}
.seach:hover{
    cursor: pointer;
}
.bold{
    font-weight: bold;
}

   .border-ot{
    border-style: solid;
    border-radius: 5px;
    border-color: $dark-blue;
    border-width: 1px;
    margin: 0px;
    padding: 10px 10px;
   }

   .cabecera-100{
    width: 100%;
    margin: 0%;
    background-color: $dark-blue;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    padding: 5px;
    border-radius: 5px;
   }
.bg-lg-green{
    background-color: $light-green;
    color: white;
    font-weight: bold;
    margin: 2px;
    border-radius: 5px;
}
.border-r-5{
    border-radius: 5px;
}
.bg-d-grey{
    background-color: $dark-grey;
    @extend.bg-lg-green
}

.color-box{
    background-color:lighten($color: $dark-blue, $amount: 55);
    color:$dark-blue;
    text-align: center;
    padding: 4px 2px;

    
}
.active-user{
    background-color:lighten($color: $greengold, $amount: 55);
    color:$greengold;
    border:1px solid $greengold;
    padding: 4px 4px;
    border-radius: 10px;
}

.inactive-user{
    background-color:lighten($color: $redgold, $amount: 55);
    color:$redgold;
    border:1px solid $redgold;
    padding: 4px 4px;
    border-radius: 10px;
}


.gray-words{
    color:$light-gray;
}
.capitalize{
    text-transform: capitalize;
}

.error{
    background-color: rgb(255, 210, 210);;
    color:rgb(144, 16, 16);
    padding: 10px 5px;
    border-radius: 5px;
}

.success{
    background-color: $light-green;
    color:darken($color: $light-green, $amount: 55);
    padding: 10px 5px;
    border-radius: 5px;
}

.brcrear{
    width: 250px;
    margin: 8px 0px;
}

.close-component{
    padding: 1px 6px;
    font-size: 10px;
    font-weight: bold;

    &:hover{
        background-color: rgb(255, 35, 35);
    
        border-radius: 50%;
        color:white;
    }
}


.general-title{
 
    padding: 20px;
    
    h3{
        font-size: 1.6em;
        font-weight: bold;
        font-size: 20px;
        color: #1766DC;
    }
}

.gray-words{
    font-size: 0.85rem;
    color:darkgrey
}