.invalidSeleccion{
    border-color: red; /* Color del borde cuando es inválido */
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); /* Sombra roja */
    background: linear-gradient(to top, #ffddd4, #fff8f4); /* Degradado de fondo */;
    transition: border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out; /* Transición más lenta al quitar el estilo */
}
.sinEfecto{
    padding: 5px;
    margin-bottom: 0px;
}
.invalid-selecction{
    color: red;
    margin-bottom: 5px;
    padding: 0%;
    transition: 0.5s ease-in-out;
}

.transparent{
    color: rgba(255, 255, 255, 0.0);
    transition: 0.5s ease-in-out;
    
}
