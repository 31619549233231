
.cuerpoContenido{
    display: grid;
    top: 20%;
    left: 20%px;
    height: 100%hv;
    font-size: 14;
}
.linea{
    margin: 0;
    padding: 0;
}

.cabecera{
    display: flex;
    padding: 0px 5px;
    margin: 0px 0px 5px 0px;
    font-size: 20px;
    height: 50px;
    align-items: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    
}
.cabecera .titulo{
    font-weight: bold;
    text-decoration: none;
    color: black;

}
.pestañaslist{
    display: flex;
    margin: 0%;
    margin-left: 0%;
    padding: 0%;
    color: white;
}

.pestañaslist .pestaña, .dark-button{
    margin: 2px;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: $primary;
    font-weight: bold;
    font-size: 14px;
    font-style: none;
    left: 0%;
    
}

.dark-button{
    color: white;
    border: none;

    &:hover{
        background-color: lighten($color: $primary, $amount:5);
    }
}

.pestañaslist .pestaña:hover{
    background-color: white;
    color: $dark-blue;
}

.cabeceraTabla{
    display: flex;
    padding: 0 2px;
    width: 100%;
    margin: 0;
    text-decoration-style: none;
    background-color: $dark-blue;
    color: white;
    height: auto;
    font-size: 14px;
    align-items: center;
}

.cabeceraTitulo{
    font-weight: bold;
}
.listado{
    display: flex;
    padding: 0 2px;
    width: 100%;
    text-decoration-style: none;
    color: $dark-blue;
    height:auto;
    font-size: 14px;
    margin: 0;
    height: 30px;
    align-items: center;
}
.listado:hover{
    background-color: rgba(40, 141, 236, 0.2)
}
.listado:hover .detalle{
    background-color: aqua;
}

.detalle{
    width: 100%;
    height: auto;
    padding: 2px 0;
    margin: 0;
    overflow: hidden;
    justify-content: center;
    transition-delay: 10ms;
}
.ubicacioneq {
    position: relative;
    font-size:70%;
    justify-content: center;
    text-justify:auto;
    text-align:justify;
}

.ubicacioneq:hover .toolti-box{
    display: inline-block;
}

.toolti-box{
    display: none;
    position: absolute;
    background-color: black;
    line-height: 20px;
    z-index: 500;
    text-align: center;
    font-size: 14px;
    padding: 7px 10px;
    border-radius: 5px;
    left: 0;
    bottom: 30px;
    color: white;
}
.toolti-box::after{
    content: "";
    display: block;
    border-top: 7px solid black;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    bottom: -7px;
    left: calc(50% - 7px);
    
}

.detalle .cabeceraequipos{
    width: 100%;
    display: flex;
    background-color: $dark-grey;
    color: white;
    padding: 0;
    margin: 0;
    font-weight: bold;
    
}
.detalle .listaequipos{
    align-items: center;
    background-color: $light-gray;
    margin: 0;
    padding: 0px;
    width:100%;
    display: flex;
}

summary{
    list-style: none;
}
summary::before{
    content: none;
}
details[open] .listado{
    background-color: rgba(40, 141, 236, 0.2)
}
.colorot :hover{
    background-color: $light-green;
}
details{
    border-radius: 5px;
    transition: 5ms;
}
.col-8{
    display: grid;
    margin: 0%;
    padding: 0%;
    border: 1px solid $light-gray;
    border-radius: 5px;
    min-height: 35px;
    align-items: center;
    text-align: center;
}
.col-8ot{
    @extend .col-8;
    grid-template-columns: 8% 20% 10% 10% 12% 10% 8% 8% 9% 5%;
}
.col-8eq{
    @extend .col-8;
    grid-template-columns: 5% 18% 13% 13% 10% 9% 9% 9% 10% 4%;
    border-style: solid;
    
}
.bg-gray{
    background-color: $dark-grey;
    color: white;
    font-weight: bold;
}
.bg-primary{
    background-color: $primary;
    color: white;
    font-weight: bold;
}

.Menubotonesot{
    width: 100%;
    background-color:gainsboro;
}
.detalle_style{
    background-color: $detailot;
    border-style: solid;
    border-color: $dark-grey;
    border-width: 1.5px;
    border-radius: 5px;
    padding: 0%;
    margin: 0%;
    margin-bottom: 5px;
}

.disabled_row{
    pointer-events: none;
    background-color: $light-gray;
}

