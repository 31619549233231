

.top_half{
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: black;

    img{
       
            max-width: 100%;
            height: auto;
        
    }

}

.bottom_half{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 50%;
    
}

.horizontal_box{
    position: relative;
    background-color: rgb(255, 255, 255);
    width: 50%;
    min-height: 30%;
    margin: 50%  0;
}


.border-gray{
    border: 1px solid $dark-grey;
}