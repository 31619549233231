.cabeceraitem{
    background-color: $dark-blue;
    color: white;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}


.formitem-9{
    display: grid;
    grid-template-columns: 20% 10% 10% 10% 10% 15% 10% 12% 3%;

}

.formitem-11{
    display: grid;
    grid-template-columns: 15% 10% 10% 10% 8% 8% 8% 8% 10% 10% 3%;

}
.formitem{
    font-size: 12px;
    margin: 0px;
    padding: 2px 0px;
    text-align: center;
    padding-right: 10px;
    text-align-last: center;
    text-align: center;
;
}
.cuerpoitem .titulo{
    font-size: 12px;
    margin: 0;
    padding: 0;
}
.userselect{
    margin: 2px;
    width: 100%;
    padding: 0px 5px;
    text-justify:inter-word;
}

.datauseri{
    display: grid;
    margin: 8px ;
    padding: 0px;
    grid-template-columns: max-content auto;
    align-content: center;    
}

.datoot .observacionot{

    grid-column-start: 1;
    grid-column-end: 3;
    margin-right: 5px;
    height: auto;
    
}
.libres{
    border-bottom: 0.5px solid $dark-blue;
}
.datauseri_2{
    display: grid;
    margin: 5px;
    padding: 0px 5px;
    border-style: solid;
    border-width: 0.2px;
    border-color: $dark-blue;
    border-radius: 5px;
    grid-template-columns: 120px auto;
    align-items: center;
}
.datauseri_3{
    display: grid;
    margin: 5px;
    padding: 5px;
    grid-template-columns: 90px auto;
    align-items: center;
}
.datoot{
    display: grid;
    grid-template-columns: 40%  35%  25%;
    margin: 0px 0px 5px 0px;
    padding: 5px 5px;
    border: 0.1px;
    border-style: solid;
    border-color: $dark-blue;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    @media (max-width:800px) {
        grid-template-columns: auto;
    }
    .resp-c-2{
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .datauseri-client{
        display: grid;
        grid-template-columns: max-content auto;
        margin: 8px;
        .client-ot{
            display: grid;
            grid-template-columns: auto max-content;
            border: 1px solid $light-grey;
            align-items: center;
            padding: 0px 2px;
            .seach{
                &:hover{
                    font-weight: bold;
                    color: $dark-blue;
                }
            }
        }
    }
}

i{
    font-size: 20px;
}
.edicion{
    margin: 0;
    padding: 10px;
    width: 30px;
    height: 30px;
    text-decoration-color: aqua;
}
.listaadd{
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.sombra-button{
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.iniciar{
    display: grid;
    margin: 0;
    padding: 0%;
    width: 100%;
}
.botoniniciar_2{
    display: grid;
    grid-template-columns: auto auto auto;
}

.pestañas{
    display: flex;
    align-content: space-between;
    margin: 10px 5px 10px 0px;}
.pestaña{
    margin: 0px;
    border-bottom: 0.5px solid $dark-grey;
}

.pestaña .textpestaña{
    padding: 0px 20px;
}

.pestañaActive{
    border-bottom: 4px solid $dark-blue;  
}

.textpestaña:active {
    border-bottom: 4px solid $dark-blue;
}


.historial{
    padding: 0;
    display: grid;
    grid-template-columns: 28% 12% 12% 10% 10% 10% 10% 7%;
    margin: 5px 0px 8px 0px;
    align-items: center;
    justify-content: center;
    justify-items:center;
}
.checkboox{
width: 25px;
height: 25px;
align-content: center;
justify-content: center;
}

li{
    text-align: center;
}

.mg_list{
    padding: 0%;
    margin: 5px 0px 8px 0px;
    align-items: center;
    justify-content: center;
    justify-items:center;
}
.UP_select{
    font-size: 30px;
    transition: width 2s, height 0.5s, transform 0.5s;
    &:hover{
        color: greenyellow;
        font-size: 35px;
        transform: rotate(180deg);
    }
}

.bi-trash{
    color: $light-red;
    :hover{
        cursor: pointer;
    }
}