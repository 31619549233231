
.bodypdf{
    
    .horizontal{
        width: 90vw;
        height: 65vh;
        background-color: none;
        overflow: hidden;
        overflow-y: scroll;
        .hoja-trabajo{
            display: grid;
            grid-template-columns: 20% 10% 10% 10% 10% 10% 10% 10% 10% ;
            padding: 0px;
            margin: 0;
        }
        .Titulocabecera{
            text-align: center;
            background-color: $primary;
            color: white;
            font-weight: bold;
        }
    } 
}