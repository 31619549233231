.item-header{
    display: grid;
    grid-template-columns: 30% 20% 15% 20% 15%;
    align-items: center;
    padding: 10px;
    .pd-5px{
        padding: 5px;
    }
    .selectedmg{
        grid-column-start: 1;
        grid-column-end: 5;
        padding: 0px 5px;
        border-radius: 5px;
    }
    .descripcion{
        grid-column-start: 1;
        grid-column-end: 6;
        padding: 0px 5px;
        margin: 0px 5px;
        border-radius: 5px;
    }
}
.itemslist{
    margin-top: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 10% 10% 10% 10% 10% 5% 5%;
}
.flex-extremos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
    background-color: rgba(112, 112, 112, 0.2);
    border-radius: 4px;


    .sedeEq{
        width: 100%;
        height: 35px;
        padding: 5px 2px;
        justify-content: center;
        border-radius: 4px;
    }
}
.flex-extremos    :hover{
    background-color: rgba(255, 70, 70, 0.2);
}