.spinner{
    border:4px solid rgba($color: #ffffff, $alpha: 0.5);
    width:50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: $primary;
    animation: spin 1s ease infinite;
    z-index: 10000;
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}