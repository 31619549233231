

.profile_img img{
    width: 100px;
    height: 100px;
}

.profile_basic{
    padding: 5px 20px;
}

.profile_basic span{
        font-weight: bold;
        font-size: 1.2rem;
}


.role{
    color: $primary;
    border:1px solid $primary;
    padding:0  10px;
    text-align: center;
    margin-left: 15px;
    border-radius: 5px;
}