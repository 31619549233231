.tab{
    display: flex;
    justify-content: space-around;
    width: 100%;

}

.tab span{
    font-size: 25px;
    border:1px solid $light-grey;
    border-radius: 50%;
    padding: 10px 15px;
    position:relative;
    z-index: 600;
}
.tab-component{

    background-color: lighten($light-grey,30);
    color: $light-grey;
}

.selected-tab{
    background-color:$primary;
    color: white;
}


.tab-line{
    border-bottom:4px solid gray;
    height:50%;
    position:absolute;
    width:100%;
    top:0
}


.check-tab{
    border-bottom:4px solid lighten($color: $primary, $amount: 20);
}
