
.new-event-calendar {
    width: 40vw;
    min-height: 30vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;

    > *:not(label){
        margin-bottom: 20px;
    }

    > *{
        width: 80%;
    }

    div{
        display: flex;
        justify-content: space-between;
    }

    button{
        border-radius: 5px;
        padding: 5px;
    }

    button[disabled]{
        opacity: 0.5;
        cursor: no-drop;
    }
}

.new-event-calendar-container-input{
    display: flex;
    flex-direction: column;
    width: 45%;

    input{
        width: 100%;
    }

    > *:not(label){
        margin-top: 5px;
        margin-bottom: 10px;
    }
}


.new-event-calendar-container-descripcion{
    display: flex;
    flex-direction: column;
    
    textarea{
        resize: none;
        max-height: 50px;

        
    }
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    ::-webkit-scrollbar:vertical {
        width:10px;
    }
    
     
    
    ::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 2px solid #f1f2f3;
    }
    
    ::-webkit-scrollbar-track {
        border-radius: 10px;  
    }
}

.new-event-calendar-container-responsables{
    display: flex;
    flex-direction: column;
}

.button-add{
    z-index: 99;
    margin: 20px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: fixed;

    right: 0;
    bottom: 0;
    background-color: white;

    cursor: pointer;
}
.button-add svg{
    width: 100%;
    color: #0dcaf0;
}

.detail-event-calendar{
    width: 35vw;
    min-height: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;

    > *{
        width: 80%;
    }

    div{
        display: flex;
        justify-content: space-between;
    }

    .button-controller{
        margin-top: 20px;
        button, a{
            width: 40%;
        }
    }

    button, a{
        border-radius: 5px;
        padding: 5px;
    }

    .calendar-detail-descripcion{
        margin-top: 15px;
    }
}

.calendar-detail-list-container{
    display: flex;
    border-bottom: 1px solid rgb(196, 196, 196);
    
    > * {
        display: flex;
        padding-top: 8px; 
        width: 50%;
        height: 100%;
    }
}


@media (max-width: 1300px) {
    .new-event-calendar,
    .detail-event-calendar {
        width: 60vw;
    }
}

@media (max-width: 920px) {
    .new-event-calendar,
    .detail-event-calendar {
        width: 80vw;
    }
}

@media (max-width: 620px) {
    .new-event-calendar,
    .detail-event-calendar {
        width: 92vw;
    }
}

