.card{
    display: felx;
    background-color: white;
    align-items: center;
    width: 250px;
    min-height: 150px;
    margin: 5px 10px 0px 0px ;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    
}
.conten_mg{
    width: 100%;
    align-content: center;
    justify-items: center;
    padding: 10px;
    border: 0.1px;
    border-style: solid;
    border-color: $dark-blue;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-size: 14px;
 
}
.conten_mg .contenido{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
    padding: 0px;
    margin: 10px 10px 10px 10px;
    width: 80%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.contenido_i{
    padding: 0px 5px;
}

.title{
    background-color: $dark-blue;
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-style: none;
    text-align: center;
    width: 100%;
    min-height: 20px;
    min-height: auto;
    padding: 10px ;
}

.botones .togEdit{
    background-color: white;
    font-size: 14px;

}
.botones .togDel{
    font-size: 14px;
    margin-left: 85%;
   background-color: white;
 
}

.botones{
    padding: 0px 5px; 
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
}