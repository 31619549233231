* {
    padding: 0;
    margin: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.91rem;
    box-sizing: border-box;
    //text-transform: capitalize;
}

html, 
body {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
}

.bottom{
    position: absolute;
  bottom: 0;
}
textarea{
    padding: 5px;
}

li{
    text-decoration: none;
    list-style: none;
    padding: 0px;
    margin: 0px;
}
ul{
    padding: 0px;
    margin: 0px;
    margin: 0px;
    list-style: none;
}
.mb-5{
    margin-bottom: 5px;
}

.mb-3{
    margin-bottom: 3px;
}

.m-1{
    margin:1px;
}
.mt-5{
    margin-top: 5px;
}

.mt-3{
    margin-top: 3px;
}
.w-90{
    width: 90% !important;
}
.w-100{
    width: 100%;
    max-width: 100%;
}

.h-100{
    height: 100vh;
    border: 1px solid rgb(21, 255, 0);
}

.ml-5{
    margin-left: 5px;
}

.ml-10{
    margin-left: 5px;

}

.ml-2{
    margin-left: 2px;
}

.pl-2{
    padding-left: 2;
}


.pt-2{
    padding-top: 2;
}

.pb-2{
    padding-bottom: 2;
}

.pr-2{
    padding-right: 2;
}

.mr-5{
    margin-right: 5px;
}
.close-x{
    color:red;
    &:hover{
        color:lighten($color: rgb(232, 68, 68), $amount: 20)
    }
}

.w-50{
    width: 50%;
}
.h-50{
    height: 50%;
    max-height: 50%;
    min-height: 59%;
}

.center-all{
    display: flex;
    justify-content: center;
    align-items: center;
}

.under_line{
    

    &:hover{
        cursor: pointer;
        text-decoration: underline;
        color: rgb(34, 171, 180) ;
    }
}

.pointer{
    cursor:pointer;
}
.one-col{
    display: grid;
    grid-template-columns: 100%;
    column-gap: 10px;
    row-gap: 10px;
}

.two-cols{
    display: grid;
    grid-template-columns:auto auto;
    column-gap: 10px;
    row-gap: 10px;
}

.three-cols{
    display: grid;
    grid-template-columns:auto auto auto; 
    column-gap: 10px;
    row-gap: 10px;
}

.four-cols{
    display: grid;
    grid-template-columns:auto auto auto auto; 
    column-gap: 10px;
    row-gap: 10px;
}


.two-rows{
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 20px;
    column-gap: 20px;
}

.three-rows{
    display: grid;
    grid-template-rows: auto auto auto;
    row-gap: 20px;
}

.border-base{
    border: 1px solid gray;
    padding: 20px 15px;
    border-radius: 10px;
}

.border-gray{
    border: 1px solid gray;
}
.border-bottom-gray{
    border-bottom: 1px solid gray;
}

.text-center >*{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-justify{
    text-align: justify;
}
.main_text{
    font-weight: bold;
    font-size: 1.5rem;
}

.flex{
    display: flex;
}
.flex-vertical{
    flex-direction: column;
}

.outline-none{
    outline: none;
}

.h-100 >*{
    height: 50%;
}
.hvw-100 {
    height:100vh;
}
.w-100{
    width: 100%;
}
.bdr{
    border-radius: 5px;
}

.border-none{
    border: none;
}

.box-options{
    position: absolute;
    background-color:white;
    width:15vw;
    min-width: 15vw;
    padding: 0;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    color:black;
}

.box-options li {
    width: 100%;
    padding: 5px 10px;

    &:hover{
        background-color:#c7c7c7;
    }
}

input{
    resize: none; 
    padding: 3px;
}

.img_form{
    border: 1px solid rgb(197, 197, 197);
    border-radius: 10px;
    width:auto;
    height: 20vh;
    object-fit: fill;
}

.justify-flex-center{
    justify-content: center;
}
.pos-abs{
    position: absolute;
}

.pos-rel{
    position: relative;
}

.right-0{
    right: 0;
}

.bottom-0{
    bottom: 0;
}

.left-0{
    left: 0;
}

.fixed-box{
    position:absolute;
    right: 120px;
    border:1px solid black;
    display:none;
    background-color: white;
    color:rgb(59, 59, 59);
    padding: 10px 15px;
    border-radius: 5px;
}

.display-in-line-block{
    display: inline-block;
}

.display-none{
    display: none;
}
.space-around{
    justify-content: space-around;
}

.space-between{
    justify-content: space-between;
}

.space-evenly{
    justify-content: space-evenly;
}

.y-scroll{
    overflow:hidden;
    overflow-y: scroll;
}
.flex-center{
    justify-content: center;
}
.wrap{
    flex-wrap:wrap;
}

.text-wrap{
    text-wrap: wrap; 
    overflow-wrap: break-word;
}

.br-5{
    border-radius: 5px;
}

.not-outline{
    outline: none;
}

.color-red{
    color:red;
}
.no-border{
    border: none;
}

.no-border-outline {
    border: none;
    outline: none;
    width: 100%;
}
.fix-table{
    width:1020px;
}

.table_responsive{
  
    overflow: auto;
    overflow-y: hidden;
}

.top-0{
    top:0;
}

.left-0{
    left: 0;
}
.flex-end{
  display:flex;
  justify-content: flex-end;
}

.fix-600{
    width:600px;
    max-width: 600px;
}

.fix-100-img{
    
    width: 10vw;
 
}

.pd-3{
    padding: 20px;
}

.pd-none{
    padding:  20px 0  !important;
}

.z-index-1{
z-index: 1;
}

.full-content{
    position: absolute;
    width: 100%;
    height: 100%;
}

.title-1{
    font-size: 2.4rem;
}


.title-2{
    font-size: 1.7rem;
}

.title-3{
    font-size: 1.2rem;
}

.margin-auto{
height: 100%;
}

.bold-text{
    font-weight: bold;
}

.text_black{
    color: black;
}