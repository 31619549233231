.listadoot{
    width: 800px;
    display: grid;
    grid-template-columns: 20% 30% 30% 20%;
    align-items: center;
    justify-content: center;
}
.accesorios{
    border-left: 1px solid white;
    border-right: 1px solid white;
    padding: 0px 10px;
    justify-content: center;
    text-align: center;
}

.listaot{
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $dark-blue;
    font-weight: bold;
    min-height: 30px;
}

.col-9-eqin{
    display: grid;
    padding: 0px;
    margin: 0px;
    grid-template-columns: 15% 15% 15% 15% 10% 10% 10% 5% 5%;
    align-items: center;
    justify-content: center;
}
.accesoriosbody{
    width: 100%px;
    padding: 0px;
    margin: 0px;
    display: grid;
    grid-template-columns: 1fr;
}

.Col-3-Ingreso{
    display: grid;
    grid-template-columns: 5% 95% ;
    text-align: center;
    padding: 0;
    margin: 0;
}
.Col-9-Ingreso{
    display: grid;
    grid-template-columns: 15% 10% 10% 10% 10% 10% 28% 7%;
    padding: 0;
    margin: 0;
    align-items: center;
}
.Col-10-Ingreso{
    display: grid;
    grid-template-columns: 15% 10% 10% 10% 10% 10% 5% 24% 3% 3%;
    padding: 0;
    margin: 0;
    align-items: center;
}
.Col-5-Ingreso{
    display: grid;
    grid-template-columns: 15% 30% 15% 20% 20%;
    border: 1px solid gray;
    border-radius: 5px;
    margin: 5px 0px;
    padding: 5px;
}
.col-2-ingreso{
    display: grid;
    grid-template-columns: 82% 18%;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.col-1-ingreso{
    text-transform: capitalize;
    display: grid;
    grid-template-columns: 1fr;
}
.cuadro{
    border: 1px solid $dark-blue;
    min-height: 25px;
    margin-right: 5px;
    border-radius: 5px;
    padding: 2px;
    text-transform: capitalize;
}
.limpiar {
    @extend.estadoin;
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 4;
    grid-column-end: 5;
    text-align: center;
    font-weight: bold;
    & :hover{
        color: $primary;
        cursor:pointer;
    }
    & .textlimpiar{
        font-size: 18px;
    }
}
.Col-5-Ingreso .observacioningreso{
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;
    border-radius: 5px;
    margin: 5px 0px;
    margin-right: 5px;
    padding: 5px;
}

.estadoin{
    border: 1px solid $dark-blue;
    border-radius: 5px;
    margin: 5px 0px;
    margin-right: 5px;
    text-align: center;
    align-items: center;
    h4{
        margin: 10px 0px
    }
}

.crear-limpiar{
    @extend.estadoin;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 4;
    display: grid;
    grid-template-columns: 1fr;
    
}
input{
    border-radius: 5px;
    border: 1px solid $dark-blue
}
.sinorden{
    height: 400px;
    margin-top: 5px;
    width: 100%;
    border: 1px solid $light-grey;
    border-radius: 5px;
    text-align: center;
    padding-top: 180px;
    h2{
        font-weight: bold;
        color: gray;
    }
}
.siningreso{
    @extend.sinorden;
    height: 100px;
    padding-top: 40px;
    h5{
        font-weight: bold;
        color: gray; 
    }
}
.col-6-ingreso{
    display: grid;
    grid-template-columns: 10% 20% 20% 15% 15% 5% 15%;
    min-height: 30px;
    margin: 5px 0px;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.a-tiempo{
    background-color: rgb(45, 105, 45);
    color: antiquewhite;
    padding: 2px;
    
}
.atrasada{
    background-color: rgb(226, 72, 72);
    color: antiquewhite;
    padding: 2px;

}
.Cierrahoy{
    background-color: rgb(206, 119, 6);
    color: antiquewhite;
    padding: 2px;
}
.a-vencer{
    background-color: rgb(230, 230, 11);
    color: rgb(48, 48, 48);
    padding: 2px;
}
.cerrada{
    background-color: $primary;
    color: rgb(48, 48, 48);
    padding: 2px;

}
.linkingreso{
    & :hover{
        cursor: pointer;
        font-weight: bold;
        color: $dark-blue;
        text-decoration:underline;
    }
}
.pieingreso{
    font-weight: bold;
    text-transform: capitalize;
    font-style: oblique;
    color: $primary;
    padding-top: 10px;
}


// seccion para ver fotos 

.contentimg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.containerimg{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    zoom: 0%;
    transition: transform .2s  ;
    .w30{
        font-size: 30px;
        opacity: 80%;
    }

}
.containerimg.zoom{
    transform: scale(1.7);
}
.slide{
    max-width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
.slide.active{
    opacity: 1;
}
.styleimg{
    height: 50vh;
    border-radius: 5px;
    margin: 0px 10px;
    border: 1px solid rgb(87, 87, 87);
    zoom: 0%;
    transition: transform .2s  ;
}
.styleimg.zoom{
    transform: scale(1.7);
}
.cursor-zin{
    cursor: zoom-in;
}
.cursor-zout{
    cursor: zoom-out;
}