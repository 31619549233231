* {
  margin: 0;
  box-sizing: border-box;
}
.header {
  background-color: $primary;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: padding 0.2s linear;
}

.header-no-logged {
  z-index: 700;
}

.perfectScrollbar{
  max-height: 400px;
  overflow-y:auto;
  overflow-x:hidden;
	position:relative;
  padding: 2px;
  margin: 0px;
  max-width: 400px;
}

.not-body{
  max-width: 390px;
  text-overflow:clip;
  padding: 0%;
  
}

.dateNot{
  font-family:Arial, Helvetica, sans-serif;
  margin: 0%;
  font-size: smaller;
  padding: 0%;
  line-height: 1.5; 
  width: 100%;
  white-space: initial;
}

.mensageNot{
  font-family: Arial, Helvetica, sans-serif;
  font-size:smaller;
  margin: 0%;
  padding: 0%;
  line-height: 1.5; 
  width: 100%;
  white-space: initial;
}

.tituloNot{
  margin: 0px;
  padding: 3px 0px 3px 0px ;
  font-size:small;
}
.timeline-panel{
  padding: 5px;
  margin: 0px 0px 0px 0px ;
  display: grid;
  align-items: center;
  min-width: 300px;
  grid-template-columns: 15% 85%;
  background-color:rgba($color: #9dbcff, $alpha: 0.1);
  border: #2f465c;
  border-radius: 10%;
  border-style:dashed;
  border-width: 0.2px;
  border-bottom: none;
}

.badge {
	padding: 3px 8px;
	text-transform: uppercase;
	line-height: 12px;
	border: 1px solid;
	font-weight: 400
}

.text-white {
	color: #fff
}

.i-false:after {
  content: none !important;
}
.rounded-circle {
  border-radius: 50% !important;
}

.logoNotificacion{
 grid-row-start: 0;
 grid-row-end: 2;

}

.header-logged {
  z-index: 100;
}
.nav {
  display: flex;
  justify-content: space-between;
  color: white;
}

.logo {
  color: white;
  text-decoration: none;
  font-size: 30px;
  font-weight: bold;
  padding: 0 40px;
  line-height: 60px;
}
.crear {
  font-size: 15px;
  margin-top: 12px;
  line-height: 15px;
}
.userNavegacion{
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  .roleUser{
    margin: 0px;
    text-align: end;
    font-size: 15px;
    padding-top: -10px;
    padding: 0%;
  }
}
.userAuth {
  margin: 0%;
  font-size: 23px;
  font-weight: bold;
  text-align: end;
}

.nav-menu {
  display: flex;
  margin-right: 20px;
}
.iconos {
  font-size: 25px;
  margin: 0 10px;
  line-height: 60px;
}
.font_sz {
  font-size: 22px;
}
.icono-link:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.icono-link {
  padding: 8px 12px;
  border-radius: 3px;
  transition: 0.5s;
}

.nav-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  line-height: 60px;
  padding-right: 0 20px;
  display: none;
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

