.form_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 1% 0;
    background-color: white;;
    position: relative;
    label{
        font-weight: bold;
    }
}

.form_group{
    width: 90%;
    margin:1%;
}

.form_group input[type="text"], 
.form_group  input[type="password"],
.form_group  input[type="string"],
.form_group  input[type="date"],
.form_group select,
.form_group textarea{
    width: 100%;
    height: 6vh;
    outline: none;
    padding: 0 1%;
    border:none;
    border-bottom: 1px solid $light-gray;
    background-color: $light-gray;
    
}

.form_group_error input[type="text"], 
.form_group_error  input[type="password"],
.form_group_error  input[type="string"],
.form_group_error  input[type="date"],
.form_group_error select,
.form_group_error textarea{
    width: 100%;
    height: 6vh;
    outline: none;
    padding: 0 1%;
    border:none;
    border-bottom: 1px solid $light-red;
    background-color: $light-gray;
    
}

.form_group_label{
    text-align: start;
    text-align: left;
    display: block;
}

.form_group button,.btn-form{
    padding: 15px 30px;
    border: none;
    background-color: $primary;
    font-size: 1.3rem;
    color:white;

    &:hover{
        background-color: lighten($color: $primary, $amount: 10%);
    }
}


.form_radio_button{
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 100%;

}