
.dashboard-container-main{
    width: 100%;
    min-height: 100vh;
}
.dashboard-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    min-height: 5px;
}

.chart-header{
    font-weight: bold;
    display: block;
    background-color: #28b5bf;
    color: white;
    padding: 7px;
}


.chart-circular-container-main{
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 30%;
    box-shadow: 0px 0px 8px -5px rgba(0,0,0,0.75);
}
.chart-circular-container{
    width: 100%;
    padding: 10px;
    background-color: rgb(251, 251, 251);
}


.chart-barra-container-main{
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 60%;
    background-color: rgb(251, 251, 251);
    box-shadow: 0px 0px 8px -5px rgba(0,0,0,0.75);
}
.chart-barra-container{
    width: 100%;
    padding: 10px;
}


.dashboards-card-container{
    position: relative;
    width: 30%;
    padding: 15px;
    margin-right: 20px;
    margin-bottom: 15px;
    color: white;
    font-weight: bold;
    background-color: #FDCA40; //FDCA40 F19953
    border-radius: 8px;
    box-shadow: 0px 0px 8px -5px rgba(0,0,0,0.75);
    overflow: hidden;
}
.dashboards-card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashboards-card-header-value{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        h2 {
            margin: 0;
            text-shadow: 0px 0px 6px #999999;
        }
    }
}
.dashboards-card-icon{
    height: 50px; 
    width: 100px; 
    fill:#ffffff;
}
.dashboards-card-description,
    .dashboards-card-header{
        position: relative;
        z-index: 50 !important; 
}
.dashboards-card-description{
    text-shadow: 0px 0px 6px #8b8b8b;
}


.dashboard-card-background {
    bottom: 0;
    left: 0;
    position: absolute;
    max-width: 100%;
    max-width: 100%;
    z-index: 0;
}



@media (max-width: 1020px) {
    .dashboards-card-container{
        width: 45%;
    }
}

@media (max-width: 880px) {
    .dashboards-card-container{
        width: 80%;
    }
    .chart-circular-container-main{
        width: 50%;
    }
    .chart-barra-container-main{
        width: 80%;
    }
}

@media (max-width: 620px) {
    .dashboards-card-container{
        width: 100%;
    }
    .chart-circular-container-main{
        width: 80%;
    }
    .chart-barra-container-main{
        width: 90%;
    }
}
