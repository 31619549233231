.my_table{
    width: 100%;
    max-width: 100%;
    border: 1px solid rgb(162, 161, 161);
    text-align: center;
}

.my_table th, .my_table td{
    border-top: 1px solid rgb(162, 161, 161);
    border-bottom: 1px solid rgb(162, 161, 161);
    padding: 1rem .5rem;
}

.my_table tbody tr{

    &:hover{
    background-color: $light-gray;
}
}

.my_table thead{
    background-color: $primary;
    color:white;
}



.item{
 
    border: 1px solid black;
   flex: 0 50%;
  
}

.my_row{
    display: flex;
    padding: 0 0 10px 0 ;
    flex-wrap: wrap;
}

