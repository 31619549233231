.modal{
    background-color:rgba($color: #000000, $alpha: 0.6);
    top:0;
    left:0;
    bottom:0;
    position:fixed !important;
    display:flex !important;
    margin:auto;
    align-items: center;
    justify-content: center;
    z-index:100;

}

.modal__contenedor{
    position: relative;
    display: inline-block;
    background-color: white;
    border-radius: 5px;
    padding-bottom: 25px;
}

.modal__header{
    background-color: #28b5bf;
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
}

.modal__close{
    fill: rgb(249, 249, 249);
    position: absolute;
    top:0;
    right:0;
    width: 50px;
    height: 50px;
    border-radius:50%;
    cursor: pointer;
}

.gray-background{
    background-color:rgba(0, 0, 0, 0.5);
    
}

.modalbg{
    width: 100vw;
    height: 100vh;
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    padding: 40px;
    align-items: center;
    justify-content: center;
    z-index: 200;
    overflow: auto;
}
.modalbgch{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color:rgba(94, 94, 94, 0.5);
    display: flex;
    padding: 40px;
    align-items: center;
    justify-content: center;
    z-index: 500;
    overflow: auto;
}

.contentmodal{ 
    background-color: white;
    min-width: 600px;
    min-height: max-content;
    margin: auto;
    position: relative;
    border-radius: 5px;
    box-shadow: rgba(100, 100,111,  0.2)0px 7px 29px 8px;
    padding: 20px;
    
}

.encabezadomodal{
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;

    H3{
        font-weight: bold;
        font-size: 20px;
        color: #1766DC;
    }
}
.closex{
    font-size: 30px;
}
.closebutton{
    position: absolute;
    right: 15px;
    top: 15px;
    background: none;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: #1766DC;
    border: none;
    

    &:hover{
        background-color: #f2f2f2;
    }

    .close{
        width: 100%;
        height: 100%;
    }
}

.col-1MD{
    display: grid;
    width: 100%vw;
    grid-template-columns: 1fr;
    align-items: center;
    align-content: center;
    padding: 10px;
}
.mg-5-all{
   margin: 5px;
}
.mg-20-top{
    margin-top: 20px;
}
.mg-20-top{
    margin-top: 10px;
}
.w-100{
 width: 100%;
}
.mg-left-90{
 left: 10px;
}
.w-100px{
    width: 100px;
}
