*{
    margin: 0;
    padding: 0;
}
.bodychange{
    width: 100%;
    border: 1px solid $dark-grey;
    border-radius: 5px;
    margin: 5px 0px;
    .changetitle{
        display: grid;
        grid-template-columns: 100%;
        background-color: $light-grey;
        justify-content: center;
        color: rgb(0, 0, 0);
        justify-items: center;
        h5{
            font-weight: bold;
        }
        .titlechange{
            display: inline-flex;
            justify-content: center;
            .itemtitle{
                margin-right: 10px;
            }
        }
    }
    .contenchange{
        padding: 5px;
        .individual{
            display:inline-flex;
            justify-content: space-between;
            margin-right: 50px;
            .cambioCard{
                padding: 5px 0px;
                text-transform: capitalize;
                display: grid;
                grid-template-columns: 1fr;
                .titlefield{
                    font-weight: bold;
                }
            }
        }
    }
    .canceleq{
        display: grid;
        grid-template-columns: 1fr;
    }
}