
.main_container{
        background-color: black 1px solid;
        margin: 70px 0 0 0;
        transition: padding 0.5s linear;
}

.pd-5{
        padding: 0 15px 0 70px; 
}
.pd-30{
      padding: 0 2% 0 330px;
}


.pdt-5{
        padding-top: 5%;
}

.pd-side-20{
        padding-left: 30%;
        padding-right: 30%;
}

.pd-25{

        padding: 0 1% 0 230px;
}