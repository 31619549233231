
.sheetw{
    width: 80vw;
    overflow: hidden;

    .obsSheet{
        width: 100%;
        margin-top: 5px;
        border: 1px solid $light-grey;
        border-radius: 5px;
        min-height: 60px;
        padding: 5px;
    }

    .contentitem{
        width: 100%;
        overflow-y: scroll;
        max-height: 50vh;
        border-radius: 5px;
        border: 1px solid $light-grey;
        
    }
    .sheetWork{
        display: grid;
        grid-template-columns: 20% 13% 12% 12% 13% 10% 10% 10%;
        width: 100%;
    }
}


.titulo_form{
    display: grid;
    grid-column-start: 1;
    grid-column-end: 11;
    justify-content: center;
    background-color: $primary;
}
.logo_ht{
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-content: center;
    background-color: white;
}
.center_ht{
    grid-column-start: 3;
    grid-column-end: 8;
    justify-content: center;
    background-color: white;
}
.end_ht{
    grid-column-start: 8;
    grid-column-end:11;
    justify-content: center;
    background-color: white;

}

.border-1{
    text-align: center;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    width: 100%;
    padding: 0px;
    margin:0%
}
.bg-primary-1{
    background-color: $primary;
}

#Contenido{
    padding: 10px;
    width: 90vw;
    height: 70vh;
}
p{
    text-align: justify;
    font-style: normal;
    justify-items: center;
    text-justify:center;
}