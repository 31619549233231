
.my_container{
    width: 100%;
    display: grid;    
    grid-template-columns: 60% 40%;
    background-color: rgb(255, 255, 255);
    padding: 0%;
    margin: 0%; 
    height: 100vh;
   }
   
   .my_container .left{
       padding: 0%;
       margin: 0%;
       max-width: 100%vh;
   }
   
   .my_container .left .fondo{ 
       max-width: 100%;
       max-height: 100%;
       min-height: 100%;
       height: auto;
   }
   
 .right{
       background-color: #3d3d3d33;
       padding: 15%;
       margin: 0%;
       display: flex;
       justify-content: center;
   }
   

   .right .loginbox{
       width: 320px;
       height: 500px;
       background-color: rgb(255, 255, 255);
       color: rgb(71, 71, 71);
       position: relative;
       box-sizing: border-box;
       padding: 30px 30px;
       border-radius: 10px;
   }
   
   .right .loginbox .imagen{
       max-width: 50%;
       max-height: 40%;
       display: block;
       margin-left: 30px;
       margin-right: auto;
       margin-bottom: 100px;
       text-align: center;
       }
    
       .right .loginbox .img-pos {
    position: absolute;
    top: 10%;
    left: 15%;
    -ms-flex-item-align: center;

   }
   .right .loginbox .titulo{
       margin: 0;
       padding: 150px 0 20px;
       text-align: center;
       }
   .right .loginbox label{
       margin: 0;
       padding: 0;
       font-weight: bold;
       display: block;
       }
   .right .loginbox input{
       width: 100%;
       margin-bottom: 20px;
       }
   .right .loginbox input[type="text"],
   .right .loginbox input[type="password"] {
       border: none;
       border-bottom: 1px solid rgb(0, 0, 0);
       background: transparent;
       outline: none;
       height: 40px;
       color: rgb(0, 0, 0);
       font-size: 16px;
       }
   .right .loginbox input[type="submit"] {
       border: none;
       outline: none;
       height: 40px;
       color: rgb(255, 255, 255);
       background-color: rgb(0, 153, 255);
       font-size: 18px;
       border-radius: 20px;
       }
   .right .loginbox input:hover[type="submit"] {
       border-width: 1px;
       border-style: solid;
       border-color: rgb(0, 153, 255);
       color: rgb(0, 153, 255);
       background-color: rgb(255, 255, 255);
   
       }
   .right .loginbox {
        
    a{
        font-size: 12px;
       text-decoration: none;
       line-height: 20px;
       color: hwb(0 29% 71%);
       &:hover{
        color: rgb(21, 0, 209);
       }
    }
    text{
        font-size: 12px;
        
    }
    .registrate{
        color: $dark-blue;
        font-weight: bold;
        font-size: 14px;
        &:hover{
            color: orange;
            
        }
    }

    }

    .link{
        &:hover{
            color:$bluehover;
            text-decoration: underline;
        }
    }


    /* src/components/TermsAndConditions.css */
.terms-and-conditions {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.terms-and-conditions h1, .terms-and-conditions h2 {
    color: #333;
}

.terms-and-conditions p {
    color: #666;
}

