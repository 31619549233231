*{
margin: 0;
padding: 0;

}

.infocleinte{
    display: grid;
    grid-template-columns: 30% 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 0;
    width: 100%;
    align-content: space-between;
}

.infoviw{
    display: grid;
    grid-template-columns: max-content auto;
    margin: 0px;
    p{
        margin: 0px ;
    }
}

.infoviw .contentinfo{
    margin: 0px
}
.infocontainer{
    display: grid;
    grid-template-columns: 98% 2%;
    padding: 5px 5px;
    border: 0.1px;
    border-style: solid;
    border-color: $dark-blue;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-size: 14px;
}
.obsotselect{
    display: flex;
    width: 100%;
    margin-top: 5px;
    align-content: space-between;
}
.btn-btn-terciary{
    margin-top: 5px;
    background-color: white;
    color: black;
    justify-items: center;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    border: 1px solid black;
    
    &:hover{
        cursor: pointer;
        background-color: $dark-blue;
        color: white;
    }


}

.checkbox{
    width: 22px;
    size: 22px;
}

.col-13{
    display: grid;
    grid-template-columns: 16% 8% 8% 10% 8% 10% 10% 8% 8% 5% 3% 3% 3%;
}

// Estilos para el contenedor de botones
.button-container {
    background-color: $menuOption; 
    display: flex;
    justify-content: flex-end; // Alinea los elementos al final del contenedor
    //margin-right: 20px; // Ajusta el margen derecho según sea necesar
  }
  
  // Estilo para los botones
  .button {
    margin-left: 10px; // Agrega un margen izquierdo entre los botones
  }
  .btn-btn-trans{
    & :hover{
        color: white;
    }
  }
  // Estilo específico para el botón que quieres que esté a la derecha
  .btn-null{
    margin-left: auto; // Hace que este botón esté siempre a la derecha
    margin-right: 10px;

  }
  .btn-btn-secundary {
    margin-left: auto; // Hace que este botón esté siempre a la derecha
    margin-right: 10px;
  }
  