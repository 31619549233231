$primary: rgb(34, 171, 180) ;
$dark-grey-2: #d3d2d4;
$light-grey: #969696;
$facebook-blue:#3B5998;
$border-gray:#c7c7c7;
$dark-blue:rgb(20, 66, 141);
$bluehover:#1c55b3;
$light-blue:#347df2;
$light-gray:#f3f0f0; 
$dark-grey: #464646;
$greengold: #0ab34a;
$redgold: #f91919;
$light-green: #98ffbf;
$light-red: rgb(180, 75, 75);
$textcolor: #ffffff;
$detailot:#d6f1df;
$speed: 500ms;
$menuOption: gainsboro

