
.body-cot{
    width: 50vw;
    .col-5-cot{
        display: grid;
        grid-template-columns: 5% 60% 30% 5%;
        align-items: center;
        .selectItem{
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

}
