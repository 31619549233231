.button-primary {
    height:5vh;
    border: none;
    background-color: $primary;
    font-size: 1.3rem;
    color:$light-gray;
    padding: 0 2%;
    cursor: pointer;
    &:hover{
        background-color: lighten($color: $primary, $amount: 10%);
    }
}

.paginate-group{
 
    display: flex;
    width: 20vw;
    justify-content: space-around;
    align-items: center;
    margin: 5px auto;
 
    
}

.arrow-button{
    color: $primary;
    &:hover{
        color:lighten($color: $primary, $amount: 10)
    }  
}
.paginate-group i {
    font-size: 1.5em;
}
.disabled_button{
    pointer-events: none !important;
    color:$dark-grey-2;
}

.size-2x i{
    font-size:2em;
}

.boton{
    margin: 5px 0px;
    padding: 5px 12px;
    border: 1px solid white;
    border-radius: 10px;
    background-color: none;
    color: white;
    font-weight: bold;
    min-height: 20px;
    cursor: pointer;
    text-align: center;
}

.btn-btn-primary{
    @extend .boton;
    background-color: $primary; 
    &:hover{
       background-color: white;
       border: 1px solid $primary;
       color: $dark-blue;
    }
   }
   .btn-btn-quaternary{
    @extend .boton;
    background-color: $dark-blue;
    &:hover{
       background-color: white;
       border: 1px solid $dark-blue;
       color: $dark-blue;
   }

   }
   .btn-btn-tertiary{
    @extend .boton;
    background-color: $dark-grey;
    &:hover{
       background-color: white;
       border: 1px solid $dark-grey;
       color: $dark-grey;
   }
}

.big-button{
    padding: 15px 40px;
    font-size: 1.5rem;
}

.btn-btn-secundary{
    @extend .boton;
    background-color: $greengold;
    &:hover{
       background-color: white;
       border: 1px solid $greengold;
       color: $dark-blue;
    }
}

.btn-btn-trans{
    margin: 5px 0px 5px 2px;
    padding: 5px  12px;
    border: none;
    border-left: 4px solid rgba(20, 66, 141, 50) ;
    background-color: transparent;
    font-weight: bold;
    min-height: 2.3rem;
    min-width: 130px;
    font-size: 0.75rem;
    cursor: pointer;
    text-align: center;
    animation-duration: 5s;
    &:hover{
        color: $dark-blue;
        font-size: 0.85rem;
        
    }
    .bi{
        margin:0px 5px ;
        font-size: 15px;
    }


}
.general-close-button{
    position: absolute;
    top:0;
    padding:0 7px;
    right: 0;
    &:hover{
        background-color: red;
    
        border-radius: 50%;
        color: white;
    }
}