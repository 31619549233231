*{
    padding: 0px;
    margin: 0px;
}

.infoseg{
    display: grid;
    grid-template-columns: max-content auto;
    height: 16px;
    
    .titleot{
        padding: 0px;
        margin: 0px;
        margin-right: 10px;
        font-weight: bold;
    }
    p{
        font-size: 16px;

    }
}

.col-3-seg{
    display: grid;
    grid-template-columns: 35% 32% 33%;
    .mg-r-5{
        margin-right: 5px;
    }
    .center{
        text-align: center;
        padding-top: 20px;
    }
    .Col-2-sub{
        height: max-content;
        display: grid;
        padding: 0px 10px;
        grid-template-columns: 120px auto ;
        align-items: center;
        .titleot{
            padding: 0px;
            margin: 0px;
            margin-right: 10px;
            font-weight: bold;
            text-align: end;
            background-color: $light-gray;
        }
        p{
            font-size: 16px;
            margin: 0px;
        }
    
        i{  
            margin-right: 10px;
            padding-top: 5px;
            font-size: 50px;
            color: $dark-grey;
        }
    }
}

.infoseg{
    min-height: max-content;
}