.rol-header{
    display: grid;
    grid-template-columns: 40% 60% ;
    textarea{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }
    .botonrol{
        width: 100%;
        margin: 0px;
        padding-right: 10px;
    }
}
.ListPermisoAdd{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.descripcion{
    padding-left: 10px;
    &:hover{
        cursor: pointer;
    }
}
.descripcionmodal{
    max-width: 550px;
    text-align: center;

}

.ListaPermisos{
    table{
    table-layout: fixed;
    width: 40vw;
    border-collapse: collapse;
    border: 1px solid rgba(0, 192, 182, 0.589);
    }

    thead th {
        width: 100%;
        padding: 10px;
        text-align: center;
    }

    thead {
        background: $dark-blue ;
        color: white;
        text-shadow: 1px 1px 1px black;
      }
      tbody td{
        display: flex;
        padding: 0px 10px;
        flex-direction: row;
        justify-content: space-between;
        text-align: center ;
        size: 35px;
        border-collapse: collapse;
      }
      tbody tr:nth-child(odd) {
        background-color: #0076e41f;
      }
      
      tbody tr:nth-child(even) {
        background-color: #00e1ff07;
      }
      
      tbody tr {
        background-image: rgb(255, 255, 255);
      }
      

    .Nopermisos{
        padding: 10px;
        border-style: groove;
        border-radius: 5px;
        border-width: 1px;
        border-color: grey;
        justify-content: center;
        h2{
            align-content: center;
            text-align: center;
            color: grey;
        }
    }
}

.Confirm{
    padding: 10px;
    border-style: groove;
    border-radius: 5px;
    border-width: 1px;
    border-color: grey;
    justify-content: center;
    h4{
        align-content: center;
        text-align: center;
        color: rgb(0, 0, 0);
    }
}
