.TabMediciones_Cabe{
    max-width: 150px;
    font-size: '0.7rem';
    border: '2px solid #ddd';
    background: aqua;
}

.cabeceraStyle{
    border: '2px solid #ddd';
    background: #05a1b6;   
}
.TabMed_body{
    font-size: '0.7rem';
    text-align: center;
}

.Tabmediciones{
    text-align: center;
    align-items: center;
    border-collapse: collapse;
}

.alertaTex{
    display: block;
    color: #ff0000;
    font-size: '0.9rem';
}

.NoalertaTex{
    display: none;
}

.Table_Acti{
    border-collapse: collapse;
    width: 100%;
    .Activ_items{
        background-color: #e4e6ce;
    }
    .Activ_lis{
        background-color: #dbffa1;
    }
}

.Table_Acti tr{
    border-bottom: 1px solid #adadad; /* Color de borde */;
}
.Table_Acti th, .Table_Acti td{
    border-right:  1px solid #cecece; /* Color de borde */
    padding: 8px; /* Espaciado interno */
    text-align: left; /* Alineación del texto */
}

.table th {
    background-color: $primary; /* Color de fondo para las celdas del encabezado */
  }

.ActiOriginal{
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #008f2b;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
    cursor: pointer;
    transition: all 0.3s ease; /* Transición suave */

    &:hover{
        background-color: #2bc2a1;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al pasar el cursor */
    }
}

.ActiVenc{
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #b9ad00;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
    cursor: pointer;
    transition: all 0.3s ease; /* Transición suave */

    &:hover{
        background-color: #d3e05f;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al pasar el cursor */
    }
}

.ActiNex{
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #b9b996;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
    transition: all 0.3s ease; /* Transición suave */

}


.file_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    background-color: #f9f9f9;
    padding: 0.5rem;
    border-radius: 4px;
}

.file_name {
    flex: 1;
    margin-right: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.delete_icon {
    flex: 0 0 5%;
    cursor: pointer;
    color: #ff0000;
}


.HeaderPatron {
    background-color: blue;
    width: 100%;
    color: white;
    border-radius: 2px;
    text-align: center;
}

.perfil-patron-container {
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    margin: 20px;
    background-color: #f9f9f9;
}

.custom-table {
    width: 100%;
    padding-top: 10px;
    margin: 10px;
    border-collapse:separate;
    border-spacing: 2px;
}

.custom-table th,
.custom-table td {
    border: 1px solid #bce6ff;
    padding: 8px;
    text-align: center;
}

.custom-table th {
    background-color: #365c63;
    color: white;
}

.custom-table th[colspan],
.custom-table th[rowspan] {
    background-color: #19aec9;
    text-align: center;
}


.custom-table td input[type="text"] {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.custom-table td.espacioBlanco {
    border: none !important;
    
}

.img_patron{
    border: 1px solid rgb(197, 197, 197);
    border-radius: 10px;
    width: 25vw;
    height: auto;
    object-fit: fill;
}
.img_patron_hv{
    width: 15vw;
    height: auto;
    object-fit: fill;
}

.Add_Button{

}

.button-text{
    display: inline-block;
    padding: 10px;
    font-weight: bold;
    border: 1px solid #ccc; /* Borde opcional */
    background-color: #f0f0f0; /* Fondo opcional */
    border-radius: 4px; /* Borde redondeado opcional */
    color: black; /* Color de texto por defecto */
    position: relative;
    bottom: 0; /* Asegura que el texto esté en la parte inferior */
    right: 0; /* Asegura que el texto esté en la parte derecha */
}

.button-text:hover {
    background-color: #e0e0e0; /* Cambio de fondo al pasar el mouse, opcional */
    color: blue; /* Color del texto al pasar el mouse */
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 4 columnas */
    gap: 10px; /* Espacio entre los elementos del grid */
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-custom {
    height: 100px; /* Define la altura fija que desees */
    width: auto; /* El ancho se ajusta automáticamente manteniendo la proporción */
    object-fit: cover; /* Ajusta la imagen para que se vea bien sin deformarse */
    border-radius: 8px; /* Bordes redondeados opcionales */
    display: block; /* Asegura que se comporte correctamente como un bloque */
    margin: 0 auto; /* Centra la imagen dentro del contenedor */
}


  



