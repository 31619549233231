.firmas{
    border-bottom: 2px solid $dark-blue;
    width: 100%;
    min-height: 300px;
}
.recibe{
    min-height: 40px;
    width: 80%;
}
.limpiar-name{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .btn-limpiar{
        min-width: 90px;
        border: none;
        background-color: none;
        color: $dark-blue;
        font-weight: bold;
        font-size: 1.2rem;
        &:hover{
            cursor: pointer;
            text-decoration:underline;
            background-color: $light-gray;
        }
    }
}
.botones{
    width: 100%;
    justify-content: center;
    padding: 20px;
}