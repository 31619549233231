.selectacta{
    min-height: 40px ;
    width: 100%;
}
.bodyacta{
    overflow-y:  scroll;
    max-height: 80vh;
    .selectActa{
        text-transform: capitalize;
        margin: 5px 0px;
        width: 70vw;
        display: grid;
        grid-template-columns: 25% 15% 15% 15% 10% 10% 10% ;
        justify-items: center;
        .inputchek{
            max-width: 30px;
            width: 25px;
        }
    }
}
.eqNoActa{
    height:50vh ;
    overflow-y: scroll;
}

.eqIngresado{
    display: grid;
    grid-template-columns: 20% 15% 15% 15% 15% 15% ; 
    width: 70vw;
    border: 1px solid $light-grey;
    border-radius: 5px;
}

.factList{
    display: grid;
    grid-template-columns: 15% 20% 20% 25% 20%;
    height: 30px;
}

.actatitle{
    display: grid;
    grid-template-columns: 10% 15% 15% 25% 20% 10%;
}

.actaselected{
    display: grid;
    grid-template-columns: 70% 30%;
    .groupsel{
        display: grid;
        grid-template-columns: 100%;
        margin: 5px 5px;
    }
    .rowacta{
        display: grid;
        grid-row-start: 1;
        grid-row-end: 3;
    }
}

.actaButton{
    display: grid;
    grid-template-columns: 50% 50%; 
}