*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sidebars{
    position: fixed;
    top: 0;
    left: 0;
    background: $dark-blue ;
    height:  100%;
    width: 60px;  
    text-align: justify;
    z-index: 200;
    padding-top:0;   
    padding-left: 0;
    overflow: hidden;
    transition: width .2s linear;
    color: white;
}

.sidebars:hover{
    width: 300px; 
}


.sidebars .nav_link {
position: relative;
list-style: none;
margin: 10px 0;
padding: 50px 0;

}

.sidebar_logo{
    padding: 0px 0;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
}


.sidebars .nav_link  i{
    height: 50px;
    min-width: 50px;
    text-align:center;
    list-style: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.sidebars .nav_link  a{
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
    }

.sidebars .nav_link a .Link_name{
font-size: 18px;
font-weight: 400;
position: relative;
color: #fff;
padding: 0;
}

.sidebars .nav_link  .icon-link {
    display:flex ;
    height: 100%;
    align-items: center;
    margin: 0;
}

.link-content{
    display: flex;
    flex-direction: column;
    &:hover{
        cursor: pointer;
        background-color: $bluehover;
    }
}


.link-content:hover > .sublink li{
    display: block;
    height: auto;
}
.sublink{
    padding: 0;
}
.sublink li{
    background-color: $light-gray;
    color: black;
    padding: 15px 25px;
    display: none;
    height: 0;
    transition: display 0.5s linear;
    text-align: left;
    &:hover{
        background-color:$dark-grey-2 ;
    }
}

.LogoBG{
    background-color: #fff;
    width: 220px;
    height: 60px;
    margin: 0;
    padding: 5px 0px;
    align-content: center;

    
    .logoSide{
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

}





